<template>
  <v-app>
      <v-main :style="'background-color:' + group.background.background">
        <div>
          <v-container style="font-family: 'Ubuntu'!important;">
            <v-row class="text-center mt-7">
              <v-col cols="12">
                <v-img
                  :src="group.logo"
                  class="my-3"
                  contain
                  height="150"
                />
              </v-col>
              <div :style="'width: 100%; color:'+group.text.color" class="mx-4">
                {{group.text.text}}
              </div>
            </v-row>
            <div style="display: flex; justify-content: center; flex-wrap: wrap;">
              <StoreItem v-for="(store) in stores" :key="store.id" :store="store" :colorButton="group.background.background" :whatsApp="whatsApp"/>
            </div>
            <v-col class="mb-4">
              <v-row class="mx-auto mb-2 text-center" style="max-width: 330px">
                <v-col cols="6" sm="6" v-for="app in apps" :key="app.link" class="mx-auto">
                  <a :href="app.link">
                    <v-img :src="app.image" :alt="app.name"></v-img>
                  </a>
                </v-col>
              </v-row>
            </v-col>

  
          </v-container>
  
        </div>
      </v-main>
  </v-app>
</template>
  

<script>
  import StoreItem from '@/components/StoreItem';
  export default {
    name: 'StoreList',
    data(){
      return{
        storesStates: [],
        stores: [],
        group:{background:{background:'#fffff'},text:{color:'#000000'}},
        apps: [
          {
            name:'App Android',
            link:'https://play.google.com/store/apps/details?id=br.com.nuctecnologia.omegapastel',
            image: require('../assets/play-store.png')
          },
          // {
          //   name:'App Store',
          //   link:'https://apps.apple.com/us/app/a%C3%A7a%C3%AD-no-quintal/id1639938340',
          //   image: require('../assets/app-store.png')
          // }
        ],
        
        whatsApp:[
          ['83 9 8884-2301','https://api.whatsapp.com/send?phone=5583988842301','d6ba34da845cae4bce261cac2f044f0a'],
          ['83 9 9143-0975','https://api.whatsapp.com/send?phone=5583991430975','1340c6db1ab5f21ddfb13cdb800c4c07']
        ],
      }
    },
    components: {
      StoreItem,
    },
    created() {
        this.$http.get('stores').then((response) => {
          this.group = response.data.group
          this.storesStates = response.data.stores
          for (const state in this.storesStates) {
            for (const city in this.storesStates[state]){
              for (const store in this.storesStates[state][city]){
                this.stores.push(this.storesStates[state][city][store])
              }
            }
          }
        });
    },
  }
</script>
