import Vue from 'vue'
import axios from 'axios'

axios.defaults.baseURL = 'https://plataforma.menuvem.com.br/application/group-c1ef8c6c4f9248811a7a8aaff5cfba2a/client-8470078e9f741ffedb3e0e1639f85bdc/'
//https://s3-sa-east-1.amazonaws.com/nuc-storage.menuvem.com.br/application/group-1471ed47b6159f4f7ab53bddbcb75f67/img/logo-a.png

Vue.use({
    install(Vue){
        Vue.prototype.$http = axios
    }
})